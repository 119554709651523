import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SelectTabService} from "../select-tab.service";


export interface ChangeRequest {
  id: number;
  subject: string;
  workflow: string;
  status: string;
  owner: string;
  created: string;
  lastUpdated: string;
}


const CHANGE_REQUEST_DATA: ChangeRequest[] = [
  {id: 746, subject: 'Test', workflow: 'BWI-Rule-Add-Parallel-Approval-Workflow', status: 'approve', owner: 'Not Assigned', created: '4 months ago', lastUpdated: '3 months ago'},
  {id: 745, subject: 'Test', workflow: 'BWI-Rule-Add-Parallel-Approval-Workflow', status: 'approve', owner: 'Not Assigned', created: '4 months ago', lastUpdated: '4 months ago'},
];


@Component({
  selector: 'app-uebersicht-queues',
  templateUrl: './open-requests.component.html',
  styleUrl: './open-requests.component.scss'
})
export class OpenRequestsComponent {

  constructor(private router:Router,
              private route: ActivatedRoute,
              private tabservice: SelectTabService) {
  }

  displayedColumns: string[] = ['id', 'subject', 'workflow', 'status', 'owner', 'created', 'lastUpdated'];
  dataSource = CHANGE_REQUEST_DATA;


  selectRow(row: ChangeRequest) {
    this.tabservice.changeData(row);
    this.router.navigate(["../offenes-Ticket"],{relativeTo: this.route});


  }
}
