import { NgModule } from '@angular/core';
import { OpenRequestsRoutingModule } from './open-requests-routing.module';
import {SharedModule} from "../Shared/shared.module";
import {OpenRequestsComponent} from "./open-requests.component";


@NgModule({
  declarations: [OpenRequestsComponent],
  imports: [
    SharedModule,
    OpenRequestsRoutingModule
  ]
})
export class OpenRequestsModule {}
