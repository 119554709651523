<mat-sidenav-container>
    <mat-sidenav-content>

      <h1 class="header">Open Change Requests</h1>

        <div class="content">
          <p class="p">My Open Change Requests</p>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- ID Column -->
            <ng-container matColumnDef="id" >
              <th mat-header-cell *matHeaderCellDef  class="mat-header-cell"> Id </th>
              <td mat-cell  (click)="selectRow(element)" *matCellDef="let element" class="blue"> {{element.id}} </td>
            </ng-container>

            <!-- Subject Column -->
            <ng-container matColumnDef="subject">
              <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Subject </th>
              <td mat-cell *matCellDef="let element"> {{element.subject}} </td>
            </ng-container>

            <!-- Workflow Column -->
            <ng-container matColumnDef="workflow">
              <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Workflow </th>
              <td mat-cell *matCellDef="let element"> {{element.workflow}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <!-- Owner Column -->
            <ng-container matColumnDef="owner">
              <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Owner </th>
              <td mat-cell *matCellDef="let element"> {{element.owner}} </td>
            </ng-container>

            <!-- Created Column -->
            <ng-container matColumnDef="created">
              <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Created </th>
              <td mat-cell *matCellDef="let element"> {{element.created}} </td>
            </ng-container>

            <!-- Last Updated Column -->
            <ng-container matColumnDef="lastUpdated">
              <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Last Updated </th>
              <td mat-cell *matCellDef="let element"> {{element.lastUpdated}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
